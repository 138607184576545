import React, { useState } from 'react'
import { Typography, CircularProgress } from '@mui/joy'
import { Logo } from '../../assets'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import { useStateContext } from '../../context/ContextProvider'
import finalUrl from '../../../config/config'
import { toast } from 'react-toastify'

const SignIn = () => {

  const { setIsSignIn } = useStateContext();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({ Email: '', Password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const clearForm = () => {
    setFormData({ Email: '', Password: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const formData = new FormData(e.target);
  
    try {
      const requestData = {
        username: formData.get('Email'),
        password: formData.get('Password')
      };
  
      const response = await fetch(finalUrl + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });
      if (response.status === 200) {
        setLoading(false);
        toast.success('Login successful');
        localStorage.setItem('admin', formData.get('Email'));
        setIsSignIn(true);
        clearForm();
        toast.success('Login successful');
        navigate("/");
      } else {
        setLoading(false);
        toast.error("Login failed due to invalid credentials");
      }
    } catch (error) {
      console.error('Error login:', error);
      setLoading(false);
    }
  };
  
  

  return (
    <div className='w-fit'>
      <div className='bg-white rounded-lg p-4 mx-2 shadow-lg flex flex-col justify-center items-center gap-4 w-full'>
        <div className='flex flex-col justify-center items-center gap-1 px-4 py-1'>
          <div className='flex justify-center items-center gap-2'>
            <img src={Logo} alt='logo' className='w-12 h-12' />
            <span className='text-blue-500 text-2xl font-semibold'>Vrisharya Technology</span>
          </div>
          <Typography variant='h1' fontSize={24} className='font-bold'>Sign In</Typography>
          <span className='text-sm text-slate-400'>Sign in to your account</span>
        </div>

        <form className='w-full' onSubmit={handleSubmit}>
          <div className='flex flex-col gap-4 mt-4 w-full px-4'>
            <div className='flex flex-col justify-start items-start gap-3'>
              <Typography fontSize={16} className='font-semibold'>Your Email</Typography>
              <TextField required={true} type='email' name='Email' value={formData.Email} onChange={handleInputChange} variant="outlined" size='small' label='Email' className='w-full' />
            </div>

            <div className='flex flex-col justify-start items-start gap-3'>
              <Typography fontSize={16} className='font-semibold'>Your Password</Typography>
              <FormControl size='small' variant="outlined" className='w-full'>
                <InputLabel required={true}>Password</InputLabel>
                <OutlinedInput
                  required={true}
                  name='Password'
                  value={formData.Password}
                  onChange={handleInputChange}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <div className='flex justify-end items-end w-full'>
                <Link to="/forgotpassword" className="text-[13px] text-blue-500 hover:underline">Forgot Password?</Link>
              </div>
            </div>

            <div className='mt-4'>
              <button type="submit" disabled={loading} className={`w-full ${loading ? "bg-blue-200" : "bg-blue-500 hover:bg-blue-600"} text-white px-4 py-2 rounded-lg`}>
                {
                  loading ? (
                    <div className='flex justify-center items-center gap-2 w-full'>
                      <CircularProgress size='sm' variant="solid" color='primary' />
                      <span className='text-blue-500'>Signing In...</span>
                    </div>
                  ) : "Sign In"
                }
              </button>
            </div>

          </div>
        </form>
        <div className='w-full my-1'>
          <p className="text-gray-600 text-[13px] text-center">
            Don't have an account yet?&nbsp;
            <Link to="/sign-up" className="text-[13px] text-blue-500 hover:underline text-center">Sign Up</Link>.
          </p>
        </div>
      </div>
    </div>
  )
}

export default SignIn