import React from 'react';
import { Logo } from '../assets/index';
import { Tooltip } from '@mui/joy';
import { NavLink } from 'react-router-dom';
import { NavLinkData } from '../data/NavLinkData';
import { LuMessagesSquare } from 'react-icons/lu';
import { RiEditCircleLine } from "react-icons/ri";
import { useStateContext } from '../context/ContextProvider';

const Sidebar = () => {

  const { handleSignOut } = useStateContext();

  const activeLink = "flex flex-col justify-center items-center gap-1 py-2 w-full rounded-lg text-white transition-all easy-in-out duration-300";
  const normalLink = "flex flex-col justify-center items-center text-white gap-1 py-2 w-full rounded-lg hover:bg-blue-700 hover:text-white transition-all easy-in-out duration-300";

  return (
    <div className='mx-auto py-4 flex flex-col justify-start items-center gap-4 overflow-auto h-screen w-36'>
      <div className='shadow-lg bg-white shadow-blue-900 rounded-full p-2 mb-3 mt-2'>
        <Tooltip variant='soft' color='primary' title="Vrisharya Technologies" arrow placement='right'>
          <img src={Logo} alt="Vrisharya Technologies" className='w-12' />
        </Tooltip>
      </div>
      <div className='flex flex-col justify-between items-center gap-20 w-full px-2'>
        <div className='w-full flex flex-col justify-center items-center gap-1'>
          {
            NavLinkData.map((item) => (
              <NavLink to={item.path} key={item.id} style={({ isActive }) => ({ backgroundColor: isActive ? "#3B82F6" : "" })} className={({ isActive }) => isActive ? activeLink : normalLink}>
                {item.icon}
                <span className='text-sm'>{item.title}</span>
              </NavLink>
            ))
          }
        </div>
        <div className='w-full flex flex-col justify-center items-center gap-1'>
          <NavLink to="/feedback" style={({ isActive }) => ({ backgroundColor: isActive ? "#3B82F6" : "" })} className={({ isActive }) => isActive ? activeLink : normalLink}>
            <LuMessagesSquare className='w-6 h-6' />
            <span className=' text-sm'>Feedback</span>
          </NavLink>
          <NavLink onClick={handleSignOut} className="mt-1 mb-2 flex flex-col justify-center items-center text-red-800 hover:bg-red-500 hover:text-white w-full rounded-lg py-2">
            <RiEditCircleLine className='-rotate-45 w-6 h-6 font-bold' />
            <span className=' text-sm'>Logout</span>
          </NavLink>
        </div>
      </div>

    </div>
  )
}

export default Sidebar