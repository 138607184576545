import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Avatar, Grid,
  TablePagination, TextField, InputLabel,
  Select, MenuItem, FormControl,
} from '@mui/material';
import { AllEmailsSkeleton } from '../../common/Skeletons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Tooltip, Typography } from '@mui/joy';
import { useEmailContext } from '../../context/EmailContextProvider';
import { MdOutlineDeleteOutline } from 'react-icons/md';

const PrimaryEmailCard = () => {

  const navigate = useNavigate();
  const param = useParams();

  const { emails, loading, deleteEmailById } = useEmailContext();

  const [searchedEmail, setSearchedEmail] = useState([]);
  const [allEmailsData, setAllEmailsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("from");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const truncateData = (data, size) => {
    if (!data) return "";
    if (data.length < size) return data;
    return data.substring(0, size) + (data.length > size ? "..." : "");
  }

  const extractDate = (dateString) => {
    if (!dateString) {
      return null;
    }

    const datePattern = /^(.*?\d{4})/;
    const match = dateString.match(datePattern);
    return match ? match[1] : null
  }

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  }

  const handleSearachOptionChange = (e) => {
    const value = e.target.value;
    setSearchBy(value);
  }

  useEffect(() => {
    if (emails) {
      setAllEmailsData(emails);
      setSearchedEmail(emails);
    }
  }, [emails]);

  useEffect(() => {
    const searchEmail = () => {
      const filteredEmail = allEmailsData.filter((email) => {
        if (searchTerm === "") return true;
        if (searchBy === "body") {
          const res = email[searchBy]?.text?.toLowerCase().match(searchTerm.toLowerCase());
          return res;
        }
        const res = email[searchBy]?.toLowerCase().match(searchTerm.toLowerCase());
        return res;
      });
      setSearchedEmail(filteredEmail);
    }

    searchEmail();
  }, [searchTerm, searchBy, allEmailsData]);


  return (
    <>
      <div className='flex justify-end items-center gap-2'>
        <FormControl size='small' className='w-32'>
          <InputLabel id="searchBy-id">Search By</InputLabel>
          <Select
            labelId="searchBy-id"
            value={searchBy}
            label="Search By"
            onChange={handleSearachOptionChange}
          >
            <MenuItem value="from">From</MenuItem>
            <MenuItem value="subject">Subject</MenuItem>
            <MenuItem value="body">Message</MenuItem>
          </Select>
        </FormControl>
        <TextField label="Search..." variant="outlined" size='small' name='searchTerm' value={searchTerm} onChange={handleSearchChange} className='!my-1 w-80' />
      </div>
      <Paper sx={{ width: "100%" }} className='flex flex-col justify-normal'>
        <TableContainer className="!rounded-lg" sx={{ minWidth: 650, height: 500 }}>
          <Table stickyHeader={true}>
            <TableHead>
              <TableRow className='' >
                <TableCell width={400} sx={{ backgroundColor: "#115293", color: "white", fontSize: "16px" }} >From</TableCell>
                <TableCell width={300} sx={{ backgroundColor: "#115293", color: "white", fontSize: "16px" }}>Subject</TableCell>
                <TableCell sx={{ backgroundColor: "#115293", color: "white", fontSize: "16px" }}>Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                loading ? (
                  <AllEmailsSkeleton />
                ) : (
                  searchedEmail.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((email, idx) => (
                    <TableRow key={idx} hover className='cursor-pointer'>
                      <TableCell onClick={() => navigate(`/all-emails/${email.email_id}`)}>
                        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                          <Grid item lg={2}>
                            <Avatar alt={email.from} src='./' className='!bg-[#4791db]' />
                          </Grid>
                          <Grid item lg={10}>
                            <Typography fontWeight={500} color="primary">{truncateData(email.from, 30)}</Typography>
                            <Typography>{extractDate(email.date)}</Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell onClick={() => navigate(`/all-emails/${email.email_id}`)}>{truncateData(email.subject, 35)}</TableCell>
                      <TableCell>
                        <Grid container>
                          <Grid item lg={11} onClick={() => navigate(`/all-emails/${email.email_id}`)} my="auto">
                            {
                              email.body?.text ? (
                                <Typography>{truncateData(email.body?.text, 50)}</Typography>
                              ) : (
                                <Typography sx={{color: "lightslategray", fontSize: "12px"}}>No message</Typography>
                                )
                            }
                          </Grid>
                          <Grid item lg={1}>
                          <Tooltip variant='soft' arrow placement='bottom' title="Delete" sx={{cursor: "pointer"}}><div onClick={() => deleteEmailById(email.email_id)} className='w-10 h-10 flex justify-center items-center rounded-full hover:bg-slate-200 transition-all ease-in-out duration-300'><MdOutlineDeleteOutline className='w-5 h-5' /></div></Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={allEmailsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}

export default PrimaryEmailCard