import React, { createContext, useState, useEffect, useContext } from 'react';

export const EmailContext = createContext();

export const EmailProvider = ({ children }) => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchEmails = async () => {
    try {
      const url = "https://api.vrisharyatechnologies.com/mail/fetch_emails?mailbox=INBOX&page=1&page_size=100";
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "server": "imap.hostinger.com",
          "email_user": "notifications@vrisharyatechnologies.com",
          "email_pass": "Notification@123"
        }),
      });

      if (res.ok) {
        const data = await res.json();
        setEmails(data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);


  const deleteEmailById = async (id) => {
    alert("Deleting.. id: " + id);
  };

  return (
    <EmailContext.Provider
      value={{
        emails, loading,
        deleteEmailById
      }}>
      {children}
    </EmailContext.Provider>
  );
};

export const useEmailContext = () => useContext(EmailContext);