import { GrAttachment } from "react-icons/gr";
import { FaSignature, FaSave  } from "react-icons/fa";
import { BiSolidMessageRounded } from "react-icons/bi";

export const TopNavData = [
  {
    id: 1,
    title: "Save",
    icon: <FaSave  className="w-5 h-5" />,
    disabled: false
  },
  {
    id: 2,
    title: "Attach",
    icon: <GrAttachment className="w-5 h-5" />,
    disabled: false
  },
  {
    id: 3,
    title: "Signature",
    icon: <FaSignature className="w-7 h-5" />,
    disabled: true
  },
  {
    id: 4,
    title: "Response",
    icon: <BiSolidMessageRounded className="w-5 h-5" />,
    disabled: false
  },
];