import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import finalUrl from '../../config/config';

const StateContext = createContext();

export const ContextProvider = ({ children }) => {

  const [isSignIn, setIsSignIn] = useState(false);

  const [loggedUser, setLoggedUser] = useState(null);

  const [loading, setLoading] = useState(false);
  const [toEmail, setToEmail] = useState("");
  const [ccEmail, setCcEmail] = useState([]);
  const [bccEmail, setBccEmail] = useState([]);
  const [isCcBccActive, setIsCcBccActive] = useState(false);

  const [emailData, setEmailData] = useState({
    fromEmail: '',
    toEmail: [],
    toEmailCc: [],
    toEmailBcc: [],
    subject: '',
    bodyMessage: '',
    filesUrls: [],
  });

  const fetchLoginFromLocalStorage = () => {
    const user = localStorage.getItem('admin');
    if (user) {
      setLoggedUser(user);
      setEmailData({ ...emailData, fromEmail: user });
    }
  };

  const handleClearEmailData = () => {
    setEmailData({
      fromEmail: '',
      toEmail: [],
      toEmailCc: [],
      toEmailBcc: [],
      subject: '',
      bodyMessage: '',
      filesUrls: [],
    });
    setToEmail("");
    setCcEmail([]);
    setBccEmail([]);
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      // const url = 'http://127.0.0.1:8000/email/background';
      const url = finalUrl + '/sendMail'
      const response = await axios.post(url, emailData);

      if (response.status === 200) {
        handleClearEmailData();
        toast.success("Email sent successfully");
        setLoading(false);
        setIsCcBccActive(false);
      } else {
        toast.error("Email not sent");
        setLoading(false);
        setIsCcBccActive(false);
      }
    } catch (e) {
      console.log(e);
      toast.error("Email not sent");
      setLoading(false);
      setIsCcBccActive(false);
    }
  }

  const handleSignOut = () => {
    localStorage.removeItem('admin');
    // localStorage.removeItem('allEmailsCounts');
    setIsSignIn(false);
  }

  return (
    <StateContext.Provider
      value={{
        isSignIn, setIsSignIn,
        loggedUser, setLoggedUser,
        loading, setLoading,
        toEmail, setToEmail,
        ccEmail, setCcEmail,
        bccEmail, setBccEmail,
        emailData, setEmailData,
        isCcBccActive, setIsCcBccActive,
        handleSendEmail,
        handleSignOut,
        fetchLoginFromLocalStorage,
      }}>
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext);