import React, { useState } from 'react'
import { GrAttachment } from 'react-icons/gr'
import { LuRefreshCcwDot } from "react-icons/lu";
import { Button, Modal, ModalClose, Sheet, Tooltip, Typography } from '@mui/joy';
import { RxCross2 } from "react-icons/rx";
import { Link } from 'react-router-dom';
import { useStateContext } from '../../context/ContextProvider';
import finalUrl from '../../../config/config';


const FileUpload = () => {

  const { emailData, setEmailData } = useStateContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);

  const maxSizeInMB = 100;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    const newTotalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);

    if (newTotalSize > maxSizeInBytes) {
      setError('Total file size exceeds 100MB');
    } else {
      setError('');
      setLoading(true);
      const newFileUrls = [];
      for (const file of selectedFiles) {
        try {
          const formData = new FormData();
          formData.append('file', file);
          const response = await fetch(finalUrl+'/upload', {
            method: 'POST',
            body: formData,
          });
          const data = await response.json();
          newFileUrls.push({
            name: file.name,
            size: file.size,
            type: file.type,
            fileUrl: URL.createObjectURL(file),
            localPath: data.filename // Update with the local path received from backend
          });
        } catch (error) {
          console.error('Error uploading file:', error);
          setError('Error uploading file');
          setLoading(false);
          return;
        }
      }
      setEmailData({ ...emailData, "filesUrls": newFileUrls });
      setLoading(false);
    }
  };

  const handleRemoveFile = (index) => {
    const newFiles = emailData.filesUrls.filter((_, i) => i !== index);
    setEmailData({ ...emailData, "filesUrls": newFiles });
  };

  const handleRefresh = () => {
    setEmailData({ ...emailData, "filesUrls": [] });
    setLoading(false);
    setError('');
  };

  return (
    <div className={`border-2 rounded-lg border-dotted ${emailData.filesUrls.length !== 0 && "border-green-400"} h-44 text-center`}>
      <div onClick={handleRefresh} className='absolute right-0 top-0 m-3 text-blue-500 z-10'>
        <Tooltip title="Clear" variant='soft' color='primary' arrow placement='left' sx={{ zIndex: "10" }}>
          <span><LuRefreshCcwDot className='cursor-pointer w-5 h-5' /></span>
        </Tooltip>
      </div>
      <input type="file" multiple onChange={handleFileChange} className='block absolute left-0 top-0 bottom-0 right-0 cursor-pointer w-full h-[11rem] rounded-lg opacity-0' />
      {
        emailData.filesUrls.length === 0 && !loading && error==='' && (
          <div className="mt-12 flex flex-col justify-center items-center gap-3">
            <span className='text-sm text-gray-400'>Maximum allowed file size is 34 MB</span>
            <button className='flex px-2 py-2 bg-slate-200 rounded-md gap-1 items-center' >
              <GrAttachment />
              <span className='text-sm'>Attach a file</span>
            </button>
          </div>
        )
      }
      {loading && (
        <div className="absolute flex items-center justify-center left-0 right-0 top-0 bottom-0 h-[11rem]">
          <svg className="w-6 h-6 mr-2 text-blue-600 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM12 24c6.627 0 12-5.373 12-12h-4a8 8 0 01-8 8v4z"></path>
          </svg>
          <span>Loading...</span>
        </div>
      )}

      {error && (
        <div className='absolute flex items-center justify-center left-0 right-0 top-0 bottom-0 h-[11rem]'>
          <p className="text-red-600">{error}</p>
        </div>
      )}

      {emailData.filesUrls.length !== 0 && !loading && (
        <div className="absolute flex flex-col items-center justify-center gap-2 left-0 right-0 top-0 bottom-0 h-[11rem]">
          <p className="text-lg font-medium text-center text-green-600">
            File Uploaded Successfully
          </p>
          <Button variant="outlined" color="success" onClick={() => setOpen(true)}>
            View All Files
          </Button>
        </div>
      )}

      <Modal open={open} onClose={() => setOpen(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        <Sheet variant="outlined" sx={{ width: 500, borderRadius: 'md', p: 3, boxShadow: 'lg'}}>
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1} >
            Selected Files are:
          </Typography>
          
          <div className='flex flex-col justify-start items-start gap-2 w-full'>
            {emailData.filesUrls.map((url, index) => (
              <div key={index} className="border rounded-lg px-3 py-1 w-full border-blue-400 flex justify-between items-center">
                <Tooltip title="View File" variant='soft' color='primary' arrow placement='right'>
                  <Link to={url.fileUrl} target='_blank' className='text-slate-500' >{url.name}</Link>
                </Tooltip>
                <RxCross2 onClick={() => handleRemoveFile(index)} className='rounded-full w-6 h-6 p-1 text-red-700 hover:bg-slate-200 cursor-pointer' />
              </div>
            ))}
          </div>
        </Sheet>
      </Modal>
    </div>
  )
}

export default FileUpload
