import { Typography } from '@mui/joy'
import React from 'react'

const Feedback = () => {
  return (
    <div className='h-screen'>
      <div className='p-4 overflow-y-hidden min-h-max h-full bg-gray-200'>
        <div className='bg-white rounded-lg h-full px-4'>
          <div className='pt-1'>
            <Typography variant='h1' fontSize={24} className='font-bold'>Feedback</Typography>
            <span className='text-sm text-slate-400'>Give the feedbacks for the betterment of company</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feedback