import { Avatar, Menu, MenuItem } from '@mui/material';

const ToEmailMenu = ({ anchorEl, open, handleClose, items, setToEmail }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {
        items.map((item) => {
          return (
            <MenuItem key={item.id} onClick={() => setToEmail(item.email)}>
              <Avatar>{item.email[0].toUpperCase() }</Avatar>{item.email}
            </MenuItem>
          )
        })
      }
    </Menu>
  )
}

export default ToEmailMenu