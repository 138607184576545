export const fromEmailData = [
  {id: 1, email: "john.doe@example.com"},
  {id: 2, email: "info@company.com"},
  {id: 3, email: "marketing@business.com"},
  {id: 4, email: "sales@website.com"},
  {id: 5, email: "contact@personalblog.com"},
];

export const toEmailData = [
  {id: 1, email: "jane.smith@example.com"},
  {id: 2, email: "support@company.com"},
  {id: 3, email: "customer.service@business.com"},
  {id: 4, email: "orders@website.com"},
  {id: 5, email: "admin@personalblog.com"},
];

export const InboxPrimarydata = [
  {
    email_id: 83,
    subject: "Re: Helo Sub",
    from: "Rakshith Kalmadi <rakshithkalmadi123@gmail.com>",
    to: "notifications@vrisharyatechnologies.com",
    cc: "rakshith.19cs074@sode-edu.in",
    date: "Sat, 8 Jun 2024 23:14:19 +0530",
    body: {
      text: "cool\r\n\r\n>\r\n",
      html: "<div dir=\"ltr\"><div><font face=\"Google Sans, Roboto, RobotoDraft, Helvetica, Arial, sans-serif\"><span style=\"font-size:14px\">cool</span></font></div><div class=\"gmail_quote\"><blockquote class=\"gmail_quote\" style=\"margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex\">\r\n</blockquote></div></div>\r\n"
    },
    attachments: []
  },
  {
    email_id: 82,
    subject: "notifications@vrisharyatechnologies.com",
    from: "Rakshith Kalmadi <rakshithkalmadi123@gmail.com>",
    to: "notifications@vrisharyatechnologies.com",
    cc: "",
    date: "Sat, 8 Jun 2024 23:13:20 +0530",
    body: {
      text: "notifications@vrisharyatechnologies.com\r\n",
      html: "<div dir=\"ltr\"><table cellpadding=\"0\" class=\"gmail-ajC\" style=\"border-spacing:0px;line-height:20px;font-family:&quot;Google Sans&quot;,Roboto,RobotoDraft,Helvetica,Arial,sans-serif;font-size:14px\"><tbody><tr class=\"gmail-ajv\"><td colspan=\"2\" tabindex=\"0\" class=\"gmail-gL\" style=\"vertical-align:top;width:auto;padding:2px 0px\"><span class=\"gmail-gI\" style=\"vertical-align:top\"><a href=\"mailto:notifications@vrisharyatechnologies.com\">notifications@vrisharyatechnologies.com</a><br></span></td></tr><tr class=\"gmail-ajv\"></tr></tbody></table><br class=\"gmail-Apple-interchange-newline\"></div>\r\n"
    },
    attachments: []
  },
  {
    email_id: 81,
    subject: "Test Me",
    from: "Rakshith Kalmadi <rakshithkalmadi123@gmail.com>",
    to: "notifications@vrisharyatechnologies.com",
    cc: "",
    date: "Sat, 8 Jun 2024 23:12:30 +0530",
    body: {
      text: "Testing\r\n",
      html: "<div dir=\"ltr\">Testing</div>\r\n"
    },
    attachments: [
      "/code/Vrisharya (4).png"
    ]
  },
  {
    email_id: 80,
    subject: "Some Sub",
    from: "Rakshith Kalmadi <rakshithkalmadi123@gmail.com>",
    to: "notifications@vrisharyatechnologies.com",
    cc: "",
    date: "Sat, 8 Jun 2024 23:10:25 +0530",
    body: {
      text: "Some Body\r\n",
      html: "<div dir=\"ltr\">Some Body</div>\r\n"
    },
    attachments: []
  },
  {
    email_id: 79,
    subject: "Helo",
    from: "Rakshith Kalmadi <rakshithkalmadi123@gmail.com>",
    to: "notifications@vrisharyatechnologies.com",
    cc: "",
    date: "Sat, 8 Jun 2024 23:09:27 +0530",
    body: {
      text: "This is body\r\n",
      html: "<div dir=\"ltr\">This is body</div>\r\n"
    },
    attachments: []
  },
  {
    email_id: 78,
    subject: "Re: Final Test",
    from: "Rakshith Kalmadi <rakshithkalmadi123@gmail.com>",
    to: "notifications@vrisharyatechnologies.com",
    cc: "",
    date: "Sat, 8 Jun 2024 23:08:28 +0530",
    body: {
      text: "hey\r\n\r\n\r\nOn Sat, Jun 8, 2024 at 11:01 PM Rakshith Kalmadi <\r\nrakshithkalmadi123@gmail.com> wrote:\r\n\r\n> hello\r\n>\r\n>\r\n> On Fri, Jun 7, 2024 at 4:59 PM <notifications@vrisharyatechnologies.com>\r\n> wrote:\r\n>\r\n>> Example test mail\r\n>>\r\n>\r\n",
      html: "<div dir=\"ltr\">hey<div><br></div></div><br><div class=\"gmail_quote\"><div dir=\"ltr\" class=\"gmail_attr\">On Sat, Jun 8, 2024 at 11:01 PM Rakshith Kalmadi &lt;<a href=\"mailto:rakshithkalmadi123@gmail.com\">rakshithkalmadi123@gmail.com</a>&gt; wrote:<br></div><blockquote class=\"gmail_quote\" style=\"margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex\"><div dir=\"ltr\">hello<div><br></div></div><br><div class=\"gmail_quote\"><div dir=\"ltr\" class=\"gmail_attr\">On Fri, Jun 7, 2024 at 4:59 PM &lt;<a href=\"mailto:notifications@vrisharyatechnologies.com\" target=\"_blank\">notifications@vrisharyatechnologies.com</a>&gt; wrote:<br></div><blockquote class=\"gmail_quote\" style=\"margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex\"><p>Example test mail</p>\r\n</blockquote></div>\r\n</blockquote></div>\r\n"
    },
    attachments: []
  },
  {
    email_id: 77,
    subject: "Helo Sub",
    from: "Rakshith Kalmadi <rakshithkalmadi123@gmail.com>",
    to: "notifications@vrisharyatechnologies.com",
    cc: "Rakshith Kalmadi <rakshithkalmadi123@gmail.com>",
    date: "Sat, 8 Jun 2024 23:02:36 +0530",
    body: {
      text: "Main Body\r\n",
      html: "<div dir=\"ltr\">Main Body</div>\r\n"
    },
    attachments: []
  },
  {
    email_id: 76,
    subject: "Re: Final Test",
    from: "Rakshith Kalmadi <rakshithkalmadi123@gmail.com>",
    to: "notifications@vrisharyatechnologies.com",
    cc: "",
    date: "Sat, 8 Jun 2024 23:01:19 +0530",
    body: {
      text: "hello\r\n\r\n\r\nOn Fri, Jun 7, 2024 at 4:59 PM <notifications@vrisharyatechnologies.com>\r\nwrote:\r\n\r\n> Example test mail\r\n>\r\n",
      html: "<div dir=\"ltr\">hello<div><br></div></div><br><div class=\"gmail_quote\"><div dir=\"ltr\" class=\"gmail_attr\">On Fri, Jun 7, 2024 at 4:59 PM &lt;<a href=\"mailto:notifications@vrisharyatechnologies.com\">notifications@vrisharyatechnologies.com</a>&gt; wrote:<br></div><blockquote class=\"gmail_quote\" style=\"margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex\"><p>Example test mail</p>\r\n</blockquote></div>\r\n"
    },
    attachments: []
  },
  {
    email_id: 75,
    subject: "Fwd: rakshithkalmadi@gmail.com",
    from: "Rakshith H Kalmadi <rakshithkalmadi@gmail.com>",
    to: "Rakshith Kalmadi <rakshithkalmadi123@gmail.com>",
    cc: "",
    date: "Sat, 8 Jun 2024 18:40:55 +0530",
    body: {
      text: "some\r\n\r\nOn Wed, Jun 5, 2024 at 10:10 PM Rakshith H Kalmadi <\r\nrakshithkalmadi@gmail.com> wrote:\r\n\r\n> Hi\r\n>\r\n> On Mon, 3 Jun 2024, 16:02 , <notifications@vrisharyatechnologies.com>\r\n> wrote:\r\n>\r\n>> Some rakshithkalmadi@gmail.com\r\n>>\r\n>\r\n",
      html: "<div dir=\"ltr\">some</div><br><div class=\"gmail_quote\"><div dir=\"ltr\" class=\"gmail_attr\">On Wed, Jun 5, 2024 at 10:10 PM Rakshith H Kalmadi &lt;<a href=\"mailto:rakshithkalmadi@gmail.com\">rakshithkalmadi@gmail.com</a>&gt; wrote:<br></div><blockquote class=\"gmail_quote\" style=\"margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex\"><div dir=\"auto\">Hi</div><br><div class=\"gmail_quote\"><div dir=\"ltr\" class=\"gmail_attr\">On Mon, 3 Jun 2024, 16:02 , &lt;<a href=\"mailto:notifications@vrisharyatechnologies.com\" target=\"_blank\">notifications@vrisharyatechnologies.com</a>&gt; wrote:<br></div><blockquote class=\"gmail_quote\" style=\"margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex\"><p>Some <a href=\"mailto:rakshithkalmadi@gmail.com\" rel=\"noreferrer\" target=\"_blank\">rakshithkalmadi@gmail.com</a></p></blockquote></div>\r\n</blockquote></div>\r\n"
    },
    attachments: []
  },
  {
    email_id: 74,
    subject: "New Sub",
    from: "notifications@vrisharyatechnologies.com",
    to: "notifications@vrisharyatechnologies.com",
    cc: "notifications@vrisharyatechnologies.com",
    date: null,
    body: {
      text: "Body of the mail\r\n",
      html: null
    },
    attachments: []
  }
];