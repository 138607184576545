import React from 'react'
import { PrimaryEmailCard } from './inbox-email/index'
import { Typography } from '@mui/joy'
import { Box } from '@mui/material'

const Mails = () => {

  return (
    <div className="h-screen bg-gray-200 p-4 overflow-y-auto w-full">
      <Box sx={{width: "100%"}} className="min-h-[calc(100vh-2rem)] bg-white rounded-lg">
        <div className="px-4 pt-1">
          <Typography variant="h1" fontSize={24} className="font-bold">
            All Email
          </Typography>
          <span className="text-sm text-slate-400">Check all emails here</span>
        </div>

        <div className='p-4 w-full'>
          <PrimaryEmailCard />
        </div>
      </Box>
    </div>
  )
}

export default Mails