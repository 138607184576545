import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Avatar, Box } from '@mui/material';
import { Tooltip } from '@mui/joy';
import { EmailDetailsMenu, EmailMenu } from '../../common';
import { BiLabel } from 'react-icons/bi';
import { RxCross2 } from "react-icons/rx";
import { FaAngleDown } from "react-icons/fa";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { LuReply, LuCornerUpLeft, LuCornerUpRight } from "react-icons/lu";
import { FaRegStar } from "react-icons/fa6";

const SingleEmailCard = () => {

  const param = useParams();

  const [emailData, setEmailData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const extractDate = (dateString) => {
    if (!dateString) {
      return null;
    }

    const datePattern = /^(.*?\d{4})/;
    const match = dateString.match(datePattern);
    return match ? match[1] : null
  }

  const extractNameAndEmail = (inputString) => {
    if (!inputString) return null;

    const nameEmailPattern = /^(.*?)\s*<(.+?)>$/;
    const match = inputString.match(nameEmailPattern);

    if (match) {
      const name = match[1].trim();
      const email = match[2].trim();
      return { name, email };
    } else {
      return null;
    }
  }

  useEffect(() => {
    const fetchOneEmail = async () => {
      setLoading(true);
      try {
        const res = await fetch(`https://api.vrisharyatechnologies.com/mail/fetch_email?mailbox=INBOX&email_id=${param.id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "server": "imap.hostinger.com",
            "email_user": "notifications@vrisharyatechnologies.com",
            "email_pass": "Notification@123"
          }),
        });

        if (res.ok) {
          const data = await res.json();
          setEmailData(data);
          const showHTML = document.getElementById('showHTML');
          console.log(data);
          showHTML.innerHTML = data?.body?.html;
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }

    fetchOneEmail();
  }, []);

  return (
    <div className="h-screen bg-gray-200 p-4 overflow-y-auto relative flex flex-col">
      <div className='relative mb-[3.8rem]'>
        <EmailMenu />
      </div>
      <div className=''>
        <Box className="min-h-[calc(100vh-6rem)] bg-white rounded-b-lg pt-4 overflow-hidden">
          {
            !loading && (
              <>
                {/* Subject */}
                <div className='ml-[5.5rem] mr-8 flex justify-start items-center gap-4'>
                  <span className='text-2xl font-semibold text-gray-600'>{emailData?.subject}</span>
                  <div className='flex justify-normal items-center gap-3'>
                    <div className='px-3 h-6 flex justify-center items-center border rounded-2xl bg-yellow-400'>
                      <span className='text-xs text-black'>External</span>
                    </div>
                    <BiLabel className='w-5 h-5' />
                    <div className='border rounded-md pl-2 w-16 h-6 flex justify-center items-center gap-2 bg-slate-100 hover:bg-slate-500 hover:text-slate-100'>
                      <span className='text-xs'>inbox</span>
                      <RxCross2 className='h-full w-full p-[2px] text-sm bg-slate-100 text-slate-600' />
                    </div>
                  </div>
                </div>

                {/* Email Info's */}
                <div className='mx-8 mt-6 flex justify-between items-center'>
                  <div className='flex justify-normal items-center gap-4'>
                    <Avatar alt={emailData?.from} src='./' className='!bg-[#4791db]' />
                    <div className='w-full'>
                      <div className='flex justify-normal items-center gap-1'>
                        <span className='text-base font-semibold'>{extractNameAndEmail(emailData?.from)?.name}</span>
                        <span className='text-xs flex justify-normal items-center'>
                          <BsChevronLeft />
                          {extractNameAndEmail(emailData?.from)?.email}
                          <BsChevronRight />
                        </span>
                      </div>
                      <div className='w-full flex justify-normal items-center gap-1'>
                        <span className='text-xs'>to me</span>
                        <div className=''>
                          <FaAngleDown className='w-4 h-4 hover:bg-slate-300 rounded cursor-pointer' onClick={handleMoreClick} />
                          <EmailDetailsMenu anchorEl={anchorEl} handleClose={handleClose} open={open} data={emailData} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-normal items-center gap-4'>
                    <span className='text-xs text-gray-500'>{extractDate(emailData?.date)}</span>
                    <Tooltip variant='soft' arrow placement='bottom' title="Star"><Link className='w-10 h-10 flex justify-center items-center rounded-full hover:bg-slate-200 transition-all ease-in-out duration-300 -mr-2'><FaRegStar /></Link></Tooltip>
                    <Tooltip variant='soft' arrow placement='bottom' title="Reply"><Link className='w-10 h-10 flex justify-center items-center rounded-full hover:bg-slate-200 transition-all ease-in-out duration-300'><LuReply /></Link></Tooltip>
                  </div>
                </div>

                {/* Body */}
                <div className='ml-[5.5rem] mr-8 mt-4'>
                  <div>
                    {emailData?.body?.text}
                  </div>
                  <div id='showHTML' />
                </div>

                {/* Attachments */}
                {
                  emailData?.attachments?.length > 0 && (
                    <div className='my-6'>
                      <hr />
                      <div className='ml-[5.5rem] mr-8 mt-4'>
                        <span className='text-sm text-gray-500'>Attachments</span>
                        <div className='flex justify-normal items-center gap-2 mt-5'>
                          {
                            emailData?.attachments?.map((attachment, idx) => (
                              <div key={idx} className='flex justify-normal items-center gap-2 border rounded-lg p-2'>
                                <span className='text-xs'>{attachment}</span>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  )
                }

                {/* Bottom Reply and Forward */}
                <div className='ml-[5.5rem] mr-8 mt-4 pb-8 flex justify-normal items-center gap-2'>
                  <Link className='flex justify-normal items-center gap-3 border w-fit px-5 py-2 rounded-3xl hover:bg-slate-200'>
                    <LuCornerUpLeft className='w-4 h-4' />
                    <span className='text-sm'>Reply</span>
                  </Link>
                  <Link className='flex justify-normal items-center gap-3 border w-fit px-5 py-2 rounded-3xl hover:bg-slate-200'>
                    <LuCornerUpRight className='w-4 h-4' />
                    <span className='text-sm'>Forward</span>
                  </Link>
                </div>
              </>
            )
          }
        </Box>
      </div>
      {/* <EmailMenu /> */}
      {/* <div className='absolute left-0 top-0 right-0 ml-4 mr-4 z-50 mt-3 overflow-hidden'>
        <div className='bg-gray-200 pt-1 z-50' />
        <EmailMenu />
      </div> */}

    </div >
  )
}

export default SingleEmailCard