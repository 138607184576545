import React from 'react';
import { Grid, Menu } from '@mui/material'

const EmailDetailsMenu = ({ anchorEl, handleClose, open, data }) => {

  const extractNameAndEmail = (inputString) => {
    if (!inputString) return null;

    const startIndex = inputString.indexOf('<');
    const endIndex = inputString.indexOf('>');

    const name = inputString.substring(0, startIndex).trim();
    const email = inputString.substring(startIndex + 1, endIndex);
    const domain = email.split('@')[1];

    return { name, email, domain };
  }

  const extractDateTime = (dateString) => {
    const newDateString = dateString + "";
    const dateTimeEnd = newDateString.indexOf('+', 0);

    if (0 !== -1 && dateTimeEnd !== -1) {
      return newDateString.slice(0, dateTimeEnd - 1).trim();
    }
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}>
      <Grid container width={500}>
        <Grid item lg={3} textAlign="end">
          <span className='text-sm text-slate-400 mr-3'>from:</span>
        </Grid>
        <Grid item lg={9}>
          <div>
            <span className='text-sm font-semibold mr-1 whitespace-nowrap'>{extractNameAndEmail(data?.from)?.name}</span>
            <span className='text-sm'>{'<' + extractNameAndEmail(data?.from)?.email + '>'}</span>
          </div>
        </Grid>
        <Grid item lg={3} textAlign="end">
          <span className='text-sm text-slate-400 mr-3'>to:</span>
        </Grid>
        <Grid item lg={9}>
          <span className='text-sm'>{data?.to}</span>
        </Grid>
        <Grid item lg={3} textAlign="end">
          <span className='text-sm text-slate-400 mr-3'>date:</span>
        </Grid>
        <Grid item lg={9}>
          <span className='text-sm'>{extractDateTime(data?.date)}</span>
        </Grid>
        <Grid item lg={3} textAlign="end">
          <span className='text-sm text-slate-400 mr-3'>subject:</span>
        </Grid>
        <Grid item lg={9}>
          <span className='text-sm'>{data?.subject}</span>
        </Grid>
        <Grid item lg={3} textAlign="end">
          <span className='text-sm text-slate-400 mr-3'>mailed-by:</span>
        </Grid>
        <Grid item lg={9}>
          <span className='text-sm'>{extractNameAndEmail(data?.from)?.domain}</span>
        </Grid>
        <Grid item lg={3} textAlign="end">
          <span className='text-sm text-slate-400 mr-3'>signed-by:</span>
        </Grid>
        <Grid item lg={9}>
          <span className='text-sm'>{extractNameAndEmail(data?.from)?.domain}</span>
        </Grid>
      </Grid>

      {/* <div onClick={handleClose} className='flex my-1 mr-4 w-full px-4'>
    //     <div className='flex justify-end mr-4 w-3/12'>
    //       <span className='text-sm text-slate-400'>security:</span>
    //     </div>
    //     <div className='w-9/12'>
    //     <span className='text-sm'>Standard encryption (TLS)</span>
    //   </div> */}
    </Menu >
  )
}

export default EmailDetailsMenu