import { FaPenToSquare } from "react-icons/fa6";
import { FaMailBulk } from "react-icons/fa";
import { Groups, Email, Settings } from '@mui/icons-material';

export const NavLinkData = [
  {
    id: 1,
    title: 'Compose',
    path: '/',
    icon: <FaPenToSquare className='font-bold mb-1 w-5 h-5' />
  },
  {
    id: 2,
    title: 'Bulk Mail',
    path: '/bulkmail',
    icon: <FaMailBulk  className='font-bold mb-1 w-5 h-5'/>
  },
  {
    id: 3,
    title: 'All Emails',
    path: '/all-emails',
    icon: <Email />
  },
  {
    id: 4,
    title: 'Contacts',
    path: '/contacts',
    icon: <Groups />
  },
  {
    id: 5,
    title: 'Settings',
    path: '/settings',
    icon: <Settings />
  },
];