import React, { useEffect, useState } from 'react'
import { RiPencilFill, RiAddFill } from 'react-icons/ri'
import { HiUserGroup } from "react-icons/hi2";
import { MessageEditor, ToEmailMenu } from './index';
import { toEmailData } from '../../data/EmailData';
import { IoIosPaperPlane } from "react-icons/io";
import { LuArrowUpRightFromCircle } from "react-icons/lu";
import { CircularProgress } from '@mui/joy';
import { useStateContext } from '../../context/ContextProvider';

const UserInfo = () => {

  const {
    emailData, setEmailData,
    loading, isCcBccActive, setIsCcBccActive,
    toEmail, setToEmail,
    ccEmail, setCcEmail,
    bccEmail, setBccEmail,
  } = useStateContext();

  // const [anchorElFrom, setAnchorElFrom] = useState(null);
  const [anchorElTo, setAnchorElTo] = useState(null);
  // const openFrom = Boolean(anchorElFrom);
  const openTo = Boolean(anchorElTo);
  const [addToEmail, setAddToEmail] = useState("");

  // const handleClickFrom = (event) => {
  //   setAnchorElFrom(event.currentTarget);
  // };

  const handleClickTo = (event) => {
    setAnchorElTo(event.currentTarget);
  };

  // const handleCloseFrom = () => {
  //   setAnchorElFrom(null);
  // };

  const handleCloseTo = () => {
    setAnchorElTo(null);
  };

  const handleInputEmailChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value })
  }

  const handleInputToEmailChange = (e) => {
    setToEmail(e.target.value)
  }

  const handleCcBccEmailChange = (e) => {
    const { name } = e.target;
    if (name === "toEmailCc" && ccEmail.length !== 0) {
      const toEmailCcFormat = ccEmail.split(/[\s;]+/).filter(item => item.trim() !== '');;
      setEmailData({ ...emailData, "toEmailCc": toEmailCcFormat });
    } else if (name === "toEmailBcc" && bccEmail.length !== 0) {
      const toEmailBccFormat = bccEmail.split(/[\s;]+/).filter(item => item.trim() !== '');;
      setEmailData({ ...emailData, "toEmailBcc": toEmailBccFormat });
    }
  }

  const handleInputToCcEmailChange = (e) => {
    setCcEmail(e.target.value)
  }

  const handleInputToBccEmailChange = (e) => {
    setBccEmail(e.target.value)
  }

  const handleCcBccChange = () => {
    setIsCcBccActive(!isCcBccActive);
  }

  // to add/append email to the input field
  useEffect(() => {
    const handleClickAddTo = (text) => {
      if (toEmail.length === 0) {
        setToEmail(text);
      } else if (toEmail[toEmail.length - 1] === "; " || toEmail[toEmail.length - 1] === " ") {
        setToEmail(toEmail.concat(" " + text + "; "));
      } else {
        setToEmail(toEmail.concat("; " + text + "; "));
      }
    };

    handleClickAddTo(addToEmail);
  }, [addToEmail]);

  useEffect(() => {
    const updateToEmailData = () => {
      const toEmailFormat = toEmail.split(/[\s;]+/).filter(item => item.trim() !== '');
      setEmailData({ ...emailData, "toEmail": toEmailFormat });
    }

    updateToEmailData();
  }, [toEmail]);

  return (
    <>
      <div className='w-full flex flex-col justify-start items-start h-full gap-2'>
        <div className='w-full flex justify-between items-center'>
          <span className='text-sm'>From</span>
          <div className='w-[80%] flex justify-start items-center'>
            <input required type="email" name='fromEmail' value={emailData.fromEmail} onChange={handleInputEmailChange} className='border px-2 py-2 text-sm w-[94%] text-gray-700 rounded-l outline-blue-300' />
            <button type='button' className='bg-stone-200 text-sm border text-gray-700 px-2 py-2 rounded-r'>
              <RiPencilFill size={20} />
            </button>
            {/* <FromEmailMenu anchorEl={anchorElFrom} open={openFrom} handleClose={handleCloseFrom} items={fromEmailData} /> */}
          </div>
        </div>

        <div className='w-full flex justify-between items-center'>
          <span className='text-sm'>To</span>
          <div className='w-[80%] flex justify-start items-center'>
            <textarea required style={{ resize: 'none' }} rows={1} name='toEmail' value={toEmail} onChange={handleInputToEmailChange} className='border px-2 py-2 text-sm w-[94%] text-gray-700 rounded-l outline-blue-300'></textarea>
            <button type='button' onClick={handleCcBccChange} className='bg-stone-200 text-sm border border-r-gray-400 text-gray-700 px-2 py-2'>
              <HiUserGroup size={20} />
            </button>
            <button type='button' onClick={handleClickTo} className='bg-stone-200 text-sm border text-gray-700 px-2 py-2 rounded-r'>
              <RiAddFill size={20} />
            </button>
            <ToEmailMenu anchorEl={anchorElTo} open={openTo} handleClose={handleCloseTo} items={toEmailData} setToEmail={setAddToEmail} />
          </div>
        </div>
        {
          isCcBccActive && (
            <div className='-mt-1 w-full flex justify-between items-center'>
              <span className='text-sm'></span>
              <div className='w-[80%] flex flex-col justify-start items-start gap-1'>
                <div className='w-full flex justify-start items-center'>
                  <span className='text-sm mr-3'>Cc:</span>
                  <textarea onBlur={handleCcBccEmailChange} style={{ resize: 'none' }} rows={1} name='toEmailCc' value={ccEmail} onChange={handleInputToCcEmailChange} className='border px-2 py-2 text-sm w-full text-gray-700 rounded-l outline-blue-300'></textarea>
                </div>
                <div className='w-full flex justify-start items-center'>
                  <span className='text-sm mr-[0.45rem]'>Bcc:</span>
                  <textarea onBlur={handleCcBccEmailChange} style={{ resize: 'none' }} rows={1} name='toEmailBcc' value={bccEmail} onChange={handleInputToBccEmailChange} className='border px-2 py-2 text-sm w-full text-gray-700 rounded-l outline-blue-300'></textarea>
                </div>
              </div>
            </div>
          )
        }

        <div className='w-full flex justify-between items-center'>
          <span className='text-sm'>Subject</span>
          <input required type="text" name='subject' value={emailData.subject} onChange={handleInputEmailChange} className='border px-2 py-2 w-[80%] text-sm text-gray-700 rounded-l outline-blue-300' />
        </div>

        <div className='w-full'>
          <MessageEditor />
        </div>

        <div className='w-full flex justify-between items-center'>
          <button disabled={loading} type='submit' className={`${loading ? "bg-slate-300 text-blue-500 cursor-not-allowed" : "bg-blue-500 text-white"}  px-3 py-2 rounded flex justify-center items-center gap-2`}>
            {loading ? <CircularProgress size="sm" variant="solid" color='primary' /> : <IoIosPaperPlane size={23} />}
            <span className='text-sm'>{loading ? "Sending..." : "Send"}</span>
          </button>
          <span className='text-blue-700 px-3 py-2 rounded flex justify-center items-center gap-2 cursor-pointer'>
            <LuArrowUpRightFromCircle size={16} />
            <span className='text-sm'>Save as Draft</span>
          </span>
        </div>
      </div>
    </>
  )
}

export default UserInfo