import { CircularProgress, Typography } from '@mui/joy'
import React, { useState } from 'react'
import { Logo } from '../../assets'
import { Link } from 'react-router-dom'

const SignUp = () => {

  const [formData, setFormData] = useState({
    mail_username: '',
    mail_password: '',
    mail_from: '',
    mail_from_name: '',
    mail_port: '',
    mail_server: '',
    personal_mail: '',
    phone: '',
  });
  const [loading, setLoading] = useState(false);

  const clearForm = () => {
    setFormData({
      mail_username: '',
      mail_password: '',
      mail_from: '',
      mail_from_name: '',
      mail_port: '',
      mail_server: '',
      personal_mail: '',
      phone: '',
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      console.log(formData);
      setLoading(false);
      clearForm();
    }, 4000);
  };

  return (
    <div className='w-[50%] my-6'>
      <div className='bg-white rounded-lg p-4 shadow-lg flex flex-col justify-center items-center gap-4 w-full'>
        <div className='flex flex-col justify-center items-center gap-1 px-4 py-1'>
          <div className='flex justify-center items-center gap-2'>
            <img src={Logo} alt='logo' className='w-12 h-12' />
            <span className='text-blue-500 text-2xl font-semibold'>Vrisharya Technology</span>
          </div>
          <Typography variant='h1' fontSize={24} className='font-bold'>Sign Up</Typography>
          <span className='text-sm text-slate-400'>Create an account</span>
        </div>

        <form className='w-full px-10 flex flex-col justify-start gap-6' onSubmit={handleSubmit}>
          <div className='grid grid-cols-2 gap-16'>
            <div className='flex flex-col justify-start items-start gap-1'>
              <Typography fontSize={16} className='font-semibold'>Mail Username</Typography>
              <input required={true} type="text" name="mail_username" value={formData.mail_username} onChange={handleInputChange} className='w-full outline-blue-400 border rounded-lg py-2 px-3 text-[14px]' />
            </div>
            <div className='flex flex-col justify-start items-start gap-1'>
              <Typography fontSize={16} className='font-semibold'>Mail Password</Typography>
              <input required={true} type="password" name="mail_password" value={formData.mail_password} onChange={handleInputChange} className='w-full outline-blue-400 border rounded-lg py-2 px-3 text-[14px]' />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-16'>
            <div className='flex flex-col justify-start items-start gap-1'>
              <Typography fontSize={16} className='font-semibold'>Mail From</Typography>
              <input required={true} type="email" name="mail_from" value={formData.mail_from} onChange={handleInputChange} className='w-full outline-blue-400 border rounded-lg py-2 px-3 text-[14px]' />
            </div>
            <div className='flex flex-col justify-start items-start gap-1'>
              <Typography fontSize={16} className='font-semibold'>Mail From Name</Typography>
              <input required={true} type="text" name="mail_from_name" value={formData.mail_from_name} onChange={handleInputChange} className='w-full outline-blue-400 border rounded-lg py-2 px-3 text-[14px]' />
            </div>
          </div>

          <div className='grid grid-cols-4 w-full gap-16'>
            <div className='flex flex-col justify-start items-start gap-1 col-span-3'>
              <Typography fontSize={16} className='font-semibold'>Mail Port</Typography>
              <input required={true} type="text" name="mail_port" value={formData.mail_port} onChange={handleInputChange} className='w-full outline-blue-400 border rounded-lg py-2 px-3 text-[14px]' />
            </div>
          </div>

          <div className='grid grid-cols-4 w-full gap-16'>
            <div className='flex flex-col justify-start items-start gap-1 col-span-3'>
              <Typography fontSize={16} className='font-semibold'>Mail Server</Typography>
              <input required={true} type="text" name="mail_server" value={formData.mail_server} onChange={handleInputChange} className='w-full outline-blue-400 border rounded-lg py-2 px-3 text-[14px]' />
            </div>
          </div>

          <div className='grid grid-cols-2 gap-16'>
            <div className='flex flex-col justify-start items-start gap-1'>
              <Typography fontSize={16} className='font-semibold'>Personal email</Typography>
              <input required={true} type="email" name="personal_mail" value={formData.personal_mail} onChange={handleInputChange} className='w-full outline-blue-400 border rounded-lg py-2 px-3 text-[14px]' />
            </div>
            <div className='flex flex-col justify-start items-start gap-1'>
              <Typography fontSize={16} className='font-semibold'>Phone No.</Typography>
              <input required={true} type="text" name="phone" value={formData.phone} onChange={handleInputChange} className='w-full outline-blue-400 border rounded-lg py-2 px-3 text-[14px]' />
            </div>
          </div>

          <div className='flex justify-center items-center w-full mt-3'>
            <button type="submit" disabled={loading} className={`${loading ? "bg-blue-200" : "bg-blue-500 hover:bg-blue-600"} text-white px-20 py-2 rounded-lg`}>
              {
                loading ? (
                  <div className='flex justify-center items-center gap-2 w-full'>
                    <CircularProgress size='sm' variant="solid" color='primary' />
                    <span className='text-blue-500'>Signing Up...</span>
                  </div>
                ) : "Sign Up"
              }
            </button>
          </div>

        </form>

        <div className='w-full mb-3 mt-1'>
          <p className="text-gray-600 text-[13px] text-center">
            Already have account?&nbsp;
            <Link to="/sign-in" className="text-[13px] text-blue-500 hover:underline text-center">Sign In</Link>.
          </p>
        </div>
      </div>
    </div>
  )
}

export default SignUp