import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import { BulkEmails, ComposeEmail, Contacts, Feedback, Mails, Settings, Sidebar } from './components/pages/index';
import { SignIn, SignUp } from './components/pages/auth-page/index';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateContext } from './components/context/ContextProvider';
import { PageNotFound } from './components/common';
import { SingleEmailCard } from './components/pages/inbox-email/index';

const App = () => {
  const { isSignIn, setIsSignIn } = useStateContext();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLogin = async () => {
      const user = localStorage.getItem('admin');
      if (user) {
        setIsSignIn(true);
      } else {
        setIsSignIn(false);
        navigate('/sign-in');
      }
    }

    fetchLogin();
  }, [isSignIn]);

  return (
    <>
      {
        !isSignIn ? (
          <div className='bg-gradient-to-r from-blue-100 via-blue-300 to-blue-500 w-full flex justify-center items-center min-h-screen'>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover={false}
              theme="light"
              transition={Slide}>
            </ToastContainer>
            
            <Routes>
              <Route path="/sign-in" element={<SignIn />} />
              <Route path='/sign-up' element={<SignUp />} />

              <Route path='/*' element={<PageNotFound />} />
            </Routes>
          </div>
        ) : (
          <div className='relative flex justify-normal w-screen min-h-screen overflow-hidden'>
            <div className='w-36 bg-black/90 overflow-y-scroll h-screen overflow-x-hidden'>
              <Sidebar />
            </div>
            <div className='bg-white w-[calc(100vw-9rem)] overflow-hidden'>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
                transition={Slide}>
              </ToastContainer>

              <Routes>
                <Route path="/" element={<ComposeEmail />} />
                <Route path="/all-emails" element={<Mails />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/feedback" element={<Feedback />} />
                  <Route path="/bulkmail" element={<BulkEmails />} />
                  
                  <Route path='/all-emails/:id' element={<SingleEmailCard />} />

                <Route path='/*' element={<PageNotFound />} />
              </Routes>
            </div>
          </div>
        )
      }
    </>
  )
}

export default App