import React from 'react';
import { Link } from 'react-router-dom';
import { useStateContext } from "../context/ContextProvider";

const PageNotFound = () => {

  const { isSignIn } = useStateContext();

  return (
    <div className={`${isSignIn && "flex justify-center items-center h-full bg-slate-100"}`} >
      <div className="rounded-lg bg-white p-8 text-center shadow-xl">
        <h1 className="mb-4 text-4xl font-bold">404 Not Found</h1>
        <p className="text-gray-600">Oops! The page you are looking for could not be found.</p>
        <Link to={isSignIn ? "/" : "/sign-in"} className="mt-4 inline-block rounded bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-600">
          Go back to {isSignIn ? "Home" : "Sign In"}
        </Link>
      </div>
    </div>
  )
};

export default PageNotFound;