import React from 'react'
import { Skeleton, TableCell, TableRow } from '@mui/material'
import { Grid } from '@mui/joy'

const AllEmailsSkeleton = () => {
  return (
    Array(5).fill().map((_, i) => (
      <TableRow key={i}>
        <TableCell>
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
            <Grid item lg={2}>
              <Skeleton variant='circular' width={45} height={45} />
            </Grid>
            <Grid item lg={10}>
              <Skeleton variant='text' width={200} height={50} />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Skeleton variant='text' width={340} height={50} />
        </TableCell>
        <TableCell>
          <Skeleton variant='text' width={440} height={50} />
        </TableCell>
      </TableRow>
    ))
  )
}

export default AllEmailsSkeleton