import React, { useEffect } from 'react';
import { TopNavData } from '../data/TopNavData';
import { Option, Select, Switch } from '@mui/joy';
import { FileUpload, UserInfo } from './send-email/index';
import { useStateContext } from '../context/ContextProvider';

const ComposeEmail = () => {

  const { handleSendEmail, fetchLoginFromLocalStorage } = useStateContext();

  useEffect(() => {
    fetchLoginFromLocalStorage();
  }, []);
  
  return (
    <>
      <form className='flex w-full' onSubmit={handleSendEmail}>
        <div className='w-4/6 flex flex-col justify-normal'>
          <div className='bg-stone-100 flex justify-center items-center h-14 border-r border-b'>
            {
              TopNavData.map((item) => {
                return (
                  <button type='button' disabled={item.disabled} key={item.id} className={`flex flex-col justify-center items-center p-2 w-20 ${item.disabled ? "text-gray-400" : "hover:bg-stone-200"}`}>
                    {item.icon}
                    <p className='text-sm'>{item.title}</p>
                  </button>
                )
              })
            }
          </div>
          <div className='w-full border-r px-4 py-2 flex flex-col justify-normal'>
            <UserInfo />
          </div>
        </div>

        <div className='w-2/6 flex flex-col justify-normal'>
          <div className='bg-stone-100 flex justify-center items-center h-14 border-r border-b'>
            <span className='text-sm'>Options and Attachments</span>
          </div>
          <div className='relative m-4 flex flex-col justify-start gap-6'>
            <FileUpload />

            <div className='w-full flex justify-between overflow-hidden'>
              <div className='flex flex-col justify-normal items-start gap-4 w-1/2'>
                <span className='text-sm'>Return receipt</span>
                <span className='text-sm'>Delivery status notification</span>
                <span className='text-sm'>Keep formatting</span>
                <span className='text-sm mt-1'>Priority</span>
                <span className='text-sm mt-2'>Save sent message in</span>
              </div>
              <div className='flex flex-col justify-start items-start gap-3 px-1 pl-6 w-1/2'>
                <span><Switch variant='outlined' size='sm' /> </span>
                <span><Switch variant='outlined' size='sm' /> </span>
                <span><Switch variant='outlined' size='sm' /> </span>
                <Select variant='outlined' size='sm' className='w-5/6'>
                  <Option value="high">High</Option>
                  <Option value="normal">Normal</Option>
                  <Option value="low">Low</Option>
                </Select>
                <Select variant='outlined' size='sm' className='w-5/6'>
                  <Option value="sent">Sent</Option>
                  <Option value="draft">Draft</Option>
                  <Option value="outbox">Outbox</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default ComposeEmail