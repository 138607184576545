import { Typography } from '@mui/joy'
import React from 'react'

const BulkEmails = () => {
  return (
    <div className='h-screen'>
      <form className='p-4 overflow-y-hidden min-h-max h-full bg-gray-200'>
        <div className='bg-white rounded-lg h-full px-4'>
          <div className='pt-1'>
            <Typography variant='h1' fontSize={24} className='font-bold'>Bulk Email Sender</Typography>
            <span className='text-sm text-slate-400'>Send multiple email's at one click</span>
          </div>
        </div>
      </form>
    </div>
  )
}

export default BulkEmails