import React from 'react'
import { Tooltip } from '@mui/joy';
import {
  MdOutlineDeleteOutline, MdOutlineArchive, MdOutlineInfo,
  MdOutlineMarkEmailUnread, MdAddTask, MdDriveFolderUpload,
  MdLabelOutline, MdOutlineMoreVert, MdOutlineEvent, MdFilterList
} from "react-icons/md";
import { FiClock } from "react-icons/fi";
import { BiLabel, BiVolumeMute } from "react-icons/bi";
import { FaArrowLeft, FaRegStar } from "react-icons/fa6";
import { Link, useParams } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';
import { useEmailContext } from '../context/EmailContextProvider';

const EmailMenu = () => {

  const param = useParams();
  
  const { deleteEmailById } = useEmailContext();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='flex flex-col min-w-[calc(100vw-11rem)] fixed z-50 h-[4.7rem] top-0 bg-gray-200'>
      <div className='flex justify-normal items-center gap-2 bg-white py-2 shadow rounded-t-lg w-full absolute top-5'>
        <div className='mx-4'>
          <Tooltip title="Back to Inbox" variant='soft' arrow placement='bottom'>
            <Link to="/all-emails" className='w-10 h-10 rounded-full hover:bg-slate-200 flex justify-center items-center transition-all ease-in-out duration-300'>
              <span><FaArrowLeft /></span>
            </Link>
          </Tooltip>

          <div className=''>

            {/* Page Navigate menu */}

          </div>
        </div>

        <div className='flex justify-normal items-center gap-3 pr-2'>
          <Tooltip variant='soft' arrow placement='bottom' title="Archive"><Link className='w-10 h-10 flex justify-center items-center rounded-full hover:bg-slate-200 transition-all ease-in-out duration-300'><MdOutlineArchive className='w-5 h-5' /></Link></Tooltip>
          <Tooltip variant='soft' arrow placement='bottom' title="Report Spam"><Link className='w-10 h-10 flex justify-center items-center rounded-full hover:bg-slate-200 transition-all ease-in-out duration-300'><MdOutlineInfo className='w-5 h-5' /></Link></Tooltip>
          <Tooltip variant='soft' arrow placement='bottom' title="Delete" sx={{cursor: "pointer"}}><div onClick={() => deleteEmailById(+param.id)} className='w-10 h-10 flex justify-center items-center rounded-full hover:bg-slate-200 transition-all ease-in-out duration-300'><MdOutlineDeleteOutline className='w-5 h-5' /></div></Tooltip>
        </div>
        <div className='bg-slate-300 w-[1px] h-6' />
        <div className='flex justify-normal items-center gap-3 px-2'>
          <Tooltip variant='soft' arrow placement='bottom' title="Mark as Unread"><Link className='w-10 h-10 flex justify-center items-center rounded-full hover:bg-slate-200 transition-all ease-in-out duration-300'><MdOutlineMarkEmailUnread className='w-5 h-5' /></Link></Tooltip>
          <Tooltip variant='soft' arrow placement='bottom' title="Snooze"><Link className='w-10 h-10 flex justify-center items-center rounded-full hover:bg-slate-200 transition-all ease-in-out duration-300'><FiClock className='w-5 h-5' /></Link></Tooltip>
          <Tooltip variant='soft' arrow placement='bottom' title="Add to Tasks"><Link className='w-10 h-10 flex justify-center items-center rounded-full hover:bg-slate-200 transition-all ease-in-out duration-300'><MdAddTask className='w-5 h-5' /></Link></Tooltip>
        </div>
        <div className='bg-slate-300 w-[1px] h-6' />
        <div className='flex justify-normal items-center gap-3 pl-2 '>
          <Tooltip variant='soft' arrow placement='bottom' title="Move To"><Link className='w-10 h-10 flex justify-center items-center rounded-full hover:bg-slate-200 transition-all ease-in-out duration-300'><MdDriveFolderUpload className='w-5 h-5' /></Link></Tooltip>
          <Tooltip variant='soft' arrow placement='bottom' title="Labels"><Link className='w-10 h-10 flex justify-center items-center rounded-full hover:bg-slate-200 transition-all ease-in-out duration-300'><MdLabelOutline className='w-5 h-5' /></Link></Tooltip>
          <Tooltip variant='soft' arrow placement='bottom' title="More"><Link onClick={handleMoreClick} className='w-10 h-10 flex justify-center items-center rounded-full hover:bg-slate-200 transition-all ease-in-out duration-300'><MdOutlineMoreVert className='w-5 h-5' /></Link></Tooltip>

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }} className='mx-5' >
            <MenuItem onClick={handleClose}><MdOutlineMarkEmailUnread className='mr-3' /> <span className='text-sm'>Mark as unread</span></MenuItem>
            <MenuItem onClick={handleClose}><BiLabel className='mr-3' /> <span className='text-sm'>Mark as important</span></MenuItem>
            <MenuItem onClick={handleClose}><FaRegStar className='mr-3' /> <span className='text-sm'>Add star</span></MenuItem>
            <MenuItem onClick={handleClose}><MdOutlineEvent className='mr-3 text-[18px]' /> <span className='text-sm'>Create event</span></MenuItem>
            <MenuItem onClick={handleClose}><MdFilterList className='mr-3 text-[18px]' /> <span className='text-sm'>Filter message like these</span></MenuItem>
            <MenuItem onClick={handleClose}><BiVolumeMute className='mr-3 text-[18px]' /> <span className='text-sm'>Mute</span></MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  )
}

export default EmailMenu